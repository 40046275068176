import { Box, Flex, Text } from '@chakra-ui/react'
import {
  Container,
  Layout,
  Loading,
  SearchInput,
  SubmitButton
} from 'components/ui'
import { useProfile } from 'contexts/profileContext'
import Mask from 'hooks/Masks'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React from 'react'

import { CustomersTable } from './table'

interface SearchCustomerPresentationalProps {
  value: string
  isShowTable: boolean
  isEmptyTable: boolean
  isDisabledButton: boolean
  isLoading: boolean
  handleInputChange: (inputValue: string) => void
  handleSearch: () => void
}

const SearchCustomerPresentational = ({
  value,
  isShowTable,
  isEmptyTable,
  isDisabledButton,
  isLoading,
  handleInputChange,
  handleSearch
}: SearchCustomerPresentationalProps) => {
  const { user } = useProfile()
  if (!user) return <Loading />

  const { removeStorageItem } = useLocalStorage()
  const removeCachedDocument = () => removeStorageItem('cachedDocument')

  return (
    <Layout isShowBackButton onPageBack={removeCachedDocument}>
      <Container>
        <Flex flexDir="column" bg="standard.white" w="100%" p="40px">
          <Text
            color="pluxee.text.primary"
            fontWeight="700"
            fontSize="md"
            mb="24px"
          >
            Consultar cliente
          </Text>

          <Flex gap="32px" alignItems="flex-end" mb="32px">
            <Box width="100%">
              <SearchInput
                size="1.5rem"
                customIcon="search"
                isError={isEmptyTable}
                onChange={handleInputChange}
                label="CNPJ ou CPF do cliente"
                placeholder="Informe o CNPJ ou CPF do cliente"
                value={value.length > 11 ? Mask.CNPJ(value) : Mask.CPF(value)}
              />

              {isEmptyTable && (
                <Text color="#B30000" fontSize="xxxs" fontWeight="600" mt="4px">
                  Cliente não encontrado, verifique e tente novamente.
                </Text>
              )}
            </Box>

            <SubmitButton
              onClick={handleSearch}
              isLoading={isLoading}
              isDisabled={isDisabledButton}
            />
          </Flex>

          {isShowTable && <CustomersTable />}
        </Flex>
      </Container>
    </Layout>
  )
}

export default SearchCustomerPresentational
