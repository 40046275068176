import { RestoreWalletOriginalValues } from 'models/request/RestoreWalletOriginalValues'
import { ICustomerRepository } from 'modules/customer/domain/repositories/ICustomerRepository'

export class RestoreWalletOriginalValuesUseCase {
  constructor(private customerRepository: ICustomerRepository) {}

  async execute(walletInfo: RestoreWalletOriginalValues): Promise<void> {
    await this.customerRepository.restoreWalletOriginalValues(walletInfo)
  }
}
