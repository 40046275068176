import { Loading } from 'components/ui'
import { useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'

import { CustomerDetailsPresentational } from '../presentational/CustomerDetailsPresentational'

const CustomerDetailsContainer = () => {
  const { user } = useProfile()
  const { removeStorageItem } = useLocalStorage()

  const removeCachedDocument = () => removeStorageItem('cachedDocument')
  const removeCachedCustomer = () => removeStorageItem('customer')

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedDocument)
    return () => {
      window.removeEventListener('beforeunload', removeCachedDocument)
    }
  }, [removeCachedDocument])

  if (!user) return <Loading />

  return <CustomerDetailsPresentational onBack={removeCachedCustomer} />
}

export default CustomerDetailsContainer
