import { Flex, Image, Text } from 'components/atoms'
import { formatCNPJ, formatCPF } from 'internationalization'
import React from 'react'

interface CustomerInfoPresentationalProps {
  tradeName: string
  document: string
  isCPF: boolean
  formattedCreationDate: string
  formattedClosureDate: string
  formattedCustomerModel: string
  formattedCustomerProduct: string
}

const CustomerInfoPresentational = ({
  tradeName,
  document,
  isCPF,
  formattedCreationDate,
  formattedClosureDate,
  formattedCustomerModel,
  formattedCustomerProduct
}: CustomerInfoPresentationalProps) => {
  return (
    <Flex flexDir="column" gap="16px">
      <Text
        color="pluxee.text.primary"
        fontSize="md"
        fontWeight="700"
        lineHeight="31.2px"
        letterSpacing="-0.8px"
      >
        Detalhes do cliente:
      </Text>

      <Flex gap="32px" alignItems="flex-start">
        <Flex id="consumer-avatar" w="84px" h="84px">
          <Image
            aria-label="Default Avatar"
            src="/img/pluxee/defaultAvatar.png"
            alt="Icone de uma pessoa"
            w="100%"
            h="100%"
          />
        </Flex>
        <Flex gap="74px">
          <Flex id="consumer-info" flexDir="column" gap="8px">
            <Flex gap="10px">
              <Details title="Empresa:" description={tradeName} />
            </Flex>

            {document && (
              <Flex gap="10px">
                <Details
                  title="CNPJ/CPF:"
                  description={
                    isCPF ? formatCPF(document) : formatCNPJ(document)
                  }
                />
              </Flex>
            )}

            <Flex gap="10px">
              <Details
                title="Produto:"
                description={formattedCustomerProduct}
              />
            </Flex>
          </Flex>

          <Flex
            id="consumer-info"
            flexDir="column"
            justifyContent="center"
            gap="8px"
            pt={1}
          >
            <Flex gap="10px">
              <Details
                minW="115px"
                title="Criado em:"
                description={formattedCreationDate}
              />
            </Flex>

            <Flex gap="10px">
              <Details
                minW="115px"
                title="Inativado em:"
                description={formattedClosureDate}
              />
            </Flex>

            <Flex gap="10px">
              <Details
                minW="115px"
                title="Modelo:"
                description={formattedCustomerModel ?? '-'}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CustomerInfoPresentational

const Details = ({
  title,
  description,
  minW = '110px'
}: {
  title: string
  description: string
  minW?: string
}) => {
  return (
    <>
      <Text
        fontWeight="700"
        color="pluxee.text.primary"
        minW={minW}
        fontSize="xxs"
        letterSpacing="-0.8px"
      >
        {title}
      </Text>
      <Text
        color="pluxee.text.primary"
        fontWeight="500"
        fontSize="xxs"
        letterSpacing="-0.8px"
      >
        {description}
      </Text>
    </>
  )
}
