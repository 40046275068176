import { useCustomer } from 'modules/customer/presentation/contexts/CustomerContext'
import React from 'react'

import CustomerWalletsPresentational from '../presentational/CustomerWalletsPresentational'

const CustomerWalletsContainer = () => {
  const { wallets, isLoadingWallets } = useCustomer()
  if (!wallets || wallets.length === 0) return <></>

  return (
    <CustomerWalletsPresentational
      wallets={Array.isArray(wallets) ? wallets : []}
      isLoadingWallets={isLoadingWallets}
    />
  )
}

export default CustomerWalletsContainer
