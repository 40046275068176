import { ICustomerRepository } from 'modules/customer/domain/repositories/ICustomerRepository'

import { GetCustomizedClientsProps } from '../queries/GetCustomizedClientsProps'

export class GetCustomizedClients {
  constructor(private customerRepository: ICustomerRepository) {}

  async execute(params: GetCustomizedClientsProps) {
    return await this.customerRepository.getCustomizedClients(params)
  }
}
