import { Button, Flex, Icon, Text, VStack } from 'components/atoms'
import { Modal, TextArea } from 'components/ui'
import {
  CreditReversalTypes,
  useWalletTransaction
} from 'contexts/walletTransactionContext'
import Mask from 'hooks/Masks'
import { useAlert } from 'hooks/useAlert'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ErrorMessage, formatErrorMessage } from 'utils/errorUtils'

type CreditReversalProps = {
  isOpen: boolean
  onClose: () => void
  onSave?: () => void
  transaction: WalletTransaction
}

export const CreditReversal = ({
  isOpen,
  onClose,
  onSave,
  transaction
}: CreditReversalProps) => {
  const { alert } = useAlert()

  const [isValidReason, setIsValidReason] = useState(false)
  const maxTextAreaLenght = 300

  const {
    addCreditReversal,
    isLoadingCreditReversal,
    isSuccessCreditReversal
  } = useWalletTransaction()

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CreditReversalTypes>()

  const onSubmit: SubmitHandler<CreditReversalTypes> = async formData => {
    if (!formData || !transaction) {
      return alert({
        id: 'creditReversalModal',
        title: 'Ocorreu um erro, tente novamente!',
        status: 'warning'
      })
    }

    addCreditReversal({
      value: String(transaction.value),
      uuid: transaction.transactionUUID,
      occorence_code: transaction.occurrenceCode,
      reason: formData.reason
    })
      .then(() => {
        if (onSave) onSave()
        alert({
          id: 'creditInTrustToast',
          title: 'Reversão de crédito realizada com sucesso!',
          status: 'success'
        })
      })
      .catch(err => {
        console.error(err)

        if (
          !err ||
          !err.response ||
          !err.response.data ||
          !err.response.data.code
        ) {
          return alert({
            id: 'creditReversalModal',
            title: 'Ocorreu um erro, tente novamente!',
            status: 'warning'
          })
        }

        const errorMessage = err.response.data.code
        const formattedErrorMessage = formatErrorMessage(
          errorMessage as ErrorMessage
        )

        return alert({
          id: 'creditReversalModal',
          status: 'error',
          title: formattedErrorMessage
        })
      })
  }

  useEffect(() => {
    if (isSuccessCreditReversal) {
      onClose()
      reset()
    }
  }, [isSuccessCreditReversal])

  const modalFooter = (
    <Flex alignItems="center" gap="16px">
      <CancelButton
        onClick={() => {
          onClose()
          reset()
        }}
      />

      <SubmitButton
        title="Confirmar"
        isDisabled={!isValidReason}
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoadingCreditReversal}
      />
    </Flex>
  )

  return (
    <Modal
      heading={translate('commons.creditReversal')}
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      decreaseMarginTop
    >
      <VStack spacing="xxs">
        <Text color="#221C46" fontSize="16px" fontWeight="500">
          {translate('commons.creditReversalTransaction')}
        </Text>

        <WarningMessage />

        <Flex flexDir="column" w="100%" gap="16px">
          <Text fontWeight="600" color="#463F5F" fontSize="14px">
            Valor do crédito provisório
          </Text>
          <Text fontWeight="600" color="#221C46" fontSize="16px">
            {Mask.moneyInput(String(transaction.value))}
          </Text>
        </Flex>

        <Flex flexDir="column" w="100%" gap="16px">
          <Text fontWeight="600" color="#463F5F" fontSize="14px">
            Código da ocorrência
          </Text>
          <Text fontWeight="600" color="#221C46" fontSize="16px">
            {transaction.occurrenceCode}
          </Text>
        </Flex>

        <TextArea
          id="reason"
          maxLenght={maxTextAreaLenght}
          title={translate('commons.reason')}
          isInvalid={!!errors.reason}
          placeholder="Informe o motivo da reversão"
          isRequired
          control={control}
          onChange={event => {
            const value = event.target.value

            if (
              Number(value.length) <= maxTextAreaLenght &&
              Number(value.length) >= 1
            ) {
              setIsValidReason(true)
            } else {
              setIsValidReason(false)
            }
          }}
        />
      </VStack>
    </Modal>
  )
}

const WarningMessage = () => (
  <Flex
    bg="#FBF2EC"
    h="100%"
    p="16px 12px"
    gap="12px"
    alignItems="center"
    mb="24px"
  >
    <Icon name="warning" color="#221C46" size="xs" />
    <Text fontSize="14px" color="#874810" fontWeight="600">
      Atenção ao preencher as informações. Reverter o crédito provisório é uma
      ação que não pode ser desfeita.
    </Text>
  </Flex>
)

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      bg="transparent"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700">
        Cancelar
      </Text>
    </Button>
  )
}

const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      bg="#1B51DC"
      color="white"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles, border: 'none' }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: 'none !important'
      }}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        {title}
      </Text>
    </Button>
  )
}
