export const isValidCpf = (cpf: string): boolean => {
  if (!cpf || cpf.length !== 11) return false

  return true
}

export const isValidCnpj = (cnpj: string): boolean => {
  if (!cnpj || cnpj.length !== 14) return false

  return true
}

export const hasValidDocumentLength = (document: string): boolean => {
  return isValidCpf(document) || isValidCnpj(document)
}
