import { RestoreWalletOriginalValues } from 'models/request/RestoreWalletOriginalValues'
import { UpdateWalletToCustomValues } from 'models/request/UpdateWalletToCustomValues'
import { GetCustomerByDocument } from 'modules/customer/application/useCases/GetCustomerByDocument'
import { GetCustomizedClients } from 'modules/customer/application/useCases/GetCustomizedClients'
import { GetWalletsByCustomerId } from 'modules/customer/application/useCases/GetWalletsByCustomerId'
import { RestoreWalletOriginalValuesUseCase } from 'modules/customer/application/useCases/RestoreWalletOriginalValues'
import { UpdateWalletToCustomValuesUseCase } from 'modules/customer/application/useCases/UpdateWalletToCustomValues'
import { CustomerRepository } from 'modules/customer/infra/repositories/CustomerRepository'
import { useQuery, useMutation } from 'react-query'

const customerRepository = new CustomerRepository()
const getCustomerByDocument = new GetCustomerByDocument(customerRepository)
const getWalletsByCustomerId = new GetWalletsByCustomerId(customerRepository)
const getCustomizedClients = new GetCustomizedClients(customerRepository)

export const useQueryGetByDocument = (document?: string) => {
  return useQuery(
    ['getByDocument', document],
    () => {
      if (!document) return null
      return getCustomerByDocument.execute({ document })
    },
    { retry: 0 }
  )
}

export const useQueryGetWalletsByCustomerId = (customerId?: number) => {
  return useQuery(
    ['getWalletsByCustomerId', customerId],
    () => {
      if (!customerId) return []
      return getWalletsByCustomerId.execute({
        customerId: customerId.toString()
      })
    },
    { retry: 0 }
  )
}

export const useMutateGetCustomizedClients = () => {
  return useMutation(() =>
    getCustomizedClients.execute({
      page: 1,
      size: 10,
      customValues: true
    })
  )
}

export const useMutateUpdateWalletToCustomValues = () => {
  const updateWalletToCustomValuesUseCase =
    new UpdateWalletToCustomValuesUseCase(customerRepository)
  return useMutation((customValues: UpdateWalletToCustomValues) =>
    updateWalletToCustomValuesUseCase.execute(customValues)
  )
}

export const useMutateRestoreOriginalValues = () => {
  const restoreWalletOriginalValuesUseCase =
    new RestoreWalletOriginalValuesUseCase(customerRepository)
  return useMutation((walletInfo: RestoreWalletOriginalValues) =>
    restoreWalletOriginalValuesUseCase.execute(walletInfo)
  )
}
