import { SignJWT } from 'jose'

const generateMockedIdToken = async (): Promise<string> => {
  const claims = {
    sub: '00u6bujfde3cDIM040x7',
    name: 'plxe test',
    email: 'plxe.test3@pluxeegroup.com',
    ver: 1,
    iss: 'https://pluxeegroup-dev.oktapreview.com',
    aud: '0oa7g2084fNf0NSKz0x7',
    iat: Math.floor(Date.now() / 1000),
    exp: 253402300799,
    jti: 'ID.jvSLnYMiKjDoH2fgpXV1x4NUW_tpsUe1YpjsuhZASY4',
    amr: ['pwd'],
    idp: '00o5g1fwwk7uKVxGj0x7',
    nonce: 'rNTewLlPnEDwK4E25WDinwrk8KKFNR4zcM7xWULG0LteyRnIubusyFe70U8Wz7Ol',
    preferred_username: 'plxe.test3@pluxeegroup.com',
    auth_time: Math.floor(Date.now() / 1000),
    at_hash: 'mZruzE566Jjt3v72NLqJqA'
  }

  const secret = new TextEncoder().encode('your-256-bit-secret')

  const jwt = await new SignJWT(claims)
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setExpirationTime(253402300799)
    .sign(secret)

  return jwt
}

export const mockToken = async () => {
  const futureExpiry = 253402300799
  const idToken = await generateMockedIdToken()

  const mockIdToken = {
    idToken,
    claims: {
      sub: '00u6bujfde3cDIM040x7',
      name: 'plxe test',
      email: 'plxe.test3@pluxeegroup.com',
      ver: 1,
      iss: 'https://pluxeegroup-dev.oktapreview.com',
      aud: '0oa7g2084fNf0NSKz0x7',
      iat: Math.floor(Date.now() / 1000),
      exp: futureExpiry,
      jti: 'ID.jvSLnYMiKjDoH2fgpXV1x4NUW_tpsUe1YpjsuhZASY4',
      amr: ['pwd'],
      idp: '00o5g1fwwk7uKVxGj0x7',
      nonce: 'rNTewLlPnEDwK4E25WDinwrk8KKFNR4zcM7xWULG0LteyRnIubusyFe70U8Wz7Ol',
      preferred_username: 'plxe.test3@pluxeegroup.com',
      auth_time: Math.floor(Date.now() / 1000),
      at_hash: 'mZruzE566Jjt3v72NLqJqA'
    },
    expiresAt: futureExpiry,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    authorizeUrl: 'https://pluxeegroup-dev.oktapreview.com/oauth2/v1/authorize',
    issuer: 'https://pluxeegroup-dev.oktapreview.com',
    clientId: '0oa7g2084fNf0NSKz0x7'
  }

  const mockAccessToken = {
    accessToken: 'mockAccessToken',
    claims: {
      ver: 1,
      jti: 'AT.y_8mK5ffNHWzae2Kkl6a3BDrAECMqlwX4eHMMK-A0pw.oar78t4sbfY9DO1TN0x6',
      iss: 'https://pluxeegroup-dev.oktapreview.com',
      aud: 'https://pluxeegroup-dev.oktapreview.com',
      sub: 'plxe.test3@pluxeegroup.com',
      iat: Math.floor(Date.now() / 1000),
      exp: futureExpiry,
      cid: '0oa7g2084fNf0NSKz0x7',
      uid: '00u6bujfde3cDIM040x7',
      scp: ['openid', 'profile', 'email', 'offline_access'],
      auth_time: Math.floor(Date.now() / 1000)
    },
    expiresAt: futureExpiry,
    tokenType: 'Bearer',
    scopes: ['openid', 'profile', 'email', 'offline_access']
  }

  const mockRefreshToken = {
    refreshToken: 'mockRefreshToken',
    expiresAt: futureExpiry,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    tokenUrl: 'https://pluxeegroup-dev.oktapreview.com/oauth2/v1/token',
    authorizeUrl: 'https://pluxeegroup-dev.oktapreview.com/oauth2/v1/authorize',
    issuer: 'https://pluxeegroup-dev.oktapreview.com'
  }

  const mockTokenStorage = {
    idToken: mockIdToken,
    accessToken: mockAccessToken,
    refreshToken: mockRefreshToken
  }

  window.localStorage.setItem(
    'okta-token-storage',
    JSON.stringify(mockTokenStorage)
  )
}
