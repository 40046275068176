import { useAlert } from 'hooks/useAlert'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCustomer } from '../../../contexts/CustomerContext'
import CustomerProfilePresentational from '../presentational/CustomerProfilePresentational'

const CustomerProfileContainer = () => {
  const { selectedCustomer } = useCustomer()
  const { alert } = useAlert()
  const navigate = useNavigate()

  useEffect(() => {
    if (!selectedCustomer?.id) {
      alert({
        title: 'Cliente não encontrado!',
        status: 'error',
        id: 'clientNotFoundToast'
      })

      navigate('/customer')
    }
  }, [selectedCustomer, alert, navigate])

  return <CustomerProfilePresentational selectedCustomer={selectedCustomer} />
}

export default CustomerProfileContainer
