import axios from 'axios'
import { AuthUtils } from 'utils/authUtils'

const walletApiEndpoint = process.env.REACT_APP_WALLET_API_ENDPOINT as string
const APIM_KEY = process.env.REACT_APP_APIM_KEY as string

export const walletApi = axios.create({
  baseURL: walletApiEndpoint,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': APIM_KEY
  }
})

walletApi.interceptors.request.use(
  config => {
    const { idToken } = AuthUtils()
    if (idToken) config!.headers!.Authorization = `Bearer ${idToken}`

    return config
  },
  error => {
    return Promise.reject(error)
  }
)
