import { Flex } from '@chakra-ui/react'
import { Container, Layout } from 'components/ui'
import React from 'react'

import CustomerProfileContainer from '../../CustomerProfile/container/CustomerProfileContainer'
import CustomerWalletsContainer from '../../CustomerWallets/container/CustomerWalletsContainer'

interface CustomerDetailsPresentationalProps {
  onBack: () => void
}

export const CustomerDetailsPresentational = ({
  onBack
}: CustomerDetailsPresentationalProps) => {
  return (
    <Layout isShowBackButton onPageBack={onBack}>
      <Container>
        <Flex
          flexDir="column"
          bg="standard.white"
          w="100%"
          p="40px"
          gap="32px"
          mb="40px"
        >
          <CustomerProfileContainer />
          <CustomerWalletsContainer />
        </Flex>
      </Container>
    </Layout>
  )
}
