import { Flex, Box, Text } from '@chakra-ui/react'
import { Tooltip } from 'components/ui'
import { StatusReasonCode, WalletStatusEnum } from 'models/AccountWallet'
import React from 'react'

export const AccountWalletStatus = ({
  status,
  statusReasonCode = null,
  hideTooltip = false,
  fontSize = '12px',
  padding = '0px 4px'
}: {
  status: WalletStatusEnum
  statusReasonCode?: StatusReasonCode | null
  hideTooltip?: boolean
  fontSize?: string
  padding?: string
}) => {
  const isActive = status === 'ACTIVE'

  const formatWalletStatus = (
    status: WalletStatusEnum,
    statusReasonCode?: StatusReasonCode | null
  ) => {
    if (status === 'BLOCKED' && statusReasonCode) {
      return 'Bloqueada'
    }

    const data = {
      ACTIVE: 'Ativa',
      PAUSED: 'Pausada',
      INACTIVE: 'Inativa',
      BLOCKED: 'Desligada',
      BLOCKED_AND_PAUSED: 'Desligada e Pausada'
    }

    return data[status]
  }

  const formatWalletTooltip = (
    status: WalletStatusEnum,
    statusReasonCode?: StatusReasonCode | null
  ) => {
    if (status === 'BLOCKED' && statusReasonCode) {
      const reasonMessages = {
        '065':
          'Carteira bloqueada em razão de irregularidade na situação cadastral junto à Receita Federal. Entrada e saída de valores não são permitidas.',
        '066':
          'Carteira bloqueada por descumprimento das políticas Pluxee. Entrada e saída de valores não são permitidas.',
        '067':
          'Carteira bloqueada por possível descumprimento das políticas Pluxee. Saída de valores não são permitidas.'
      }
      return reasonMessages[statusReasonCode] || statusReasonCode
    }

    const defaultMessages = {
      ACTIVE: '',
      PAUSED: 'Pausada pelo Colaborador, somente ele poderá desfazer esta ação',
      INACTIVE:
        'Inativada pela Pluxee, somente a Pluxee poderá desfazer esta ação.',
      BLOCKED:
        'Desligada pelo Cliente RH, somente ele poderá desfazer esta ação',
      BLOCKED_AND_PAUSED:
        'Desligada pelo Cliente RH, somente ele poderá desfazer esta ação. O status ”Pausada” foi realizado pelo Colaborador e só poderá ser desfeito por ele.'
    }
    return defaultMessages[status]
  }

  return (
    <Tooltip
      label={hideTooltip ? '' : formatWalletTooltip(status, statusReasonCode)}
    >
      <Flex
        bg="standard.white"
        w="fit-content"
        minH="18px"
        p={padding}
        alignItems="center"
        justifyContent="center"
        gap="4px"
        border="1px solid"
        borderColor={isActive ? '#01D253' : '#B30000'}
      >
        <Box
          w="8px"
          h="8px"
          borderRadius="50%"
          bg={isActive ? '#01D253' : '#B30000'}
        />
        <Text
          letterSpacing="-0.6px"
          color="#221C46"
          fontWeight="500"
          fontSize={fontSize}
          lineHeight="16px"
        >
          {formatWalletStatus(status, statusReasonCode) ?? 'Não disponivel'}
        </Text>
      </Flex>
    </Tooltip>
  )
}
