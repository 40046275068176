import { Button, Center } from '@chakra-ui/react'
import { Icon, Text } from 'components/atoms'
import { Table } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { usePagination } from 'hooks/usePagination'
import { formatCNPJ, formatCPF } from 'internationalization'
import { useCustomer } from 'modules/customer/presentation/contexts/CustomerContext'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { limitString } from 'utils/stringUtils'

import { Customer } from '../../../../domain/entities/Customer'

import Skeleton from './skeleton'

export const CustomersTable = () => {
  const navigate = useNavigate()

  const { customers, customersPagination, isLoading } = useCustomer()

  console.log(customers)
  const { setStorageItem } = useLocalStorage()

  const { currentPage, handlePrevPage, handleNextPage, handleOnChangePage } =
    usePagination({
      initialPage: customersPagination?.currentPage ?? 0
    })

  const columns: CellProps[] = [
    {
      title: 'Empresa',
      width: '25%',
      render: (data: Record<keyof Customer, any>) => {
        const isGreaterThan40 = data.name?.length >= 40
        const formattedName = isGreaterThan40
          ? limitString(data.name, 40)
          : data.name

        return (
          <Text fontSize="xxs" lineHeight="150%" color="pluxee.text.secondary">
            {formattedName}
          </Text>
        )
      }
    },
    {
      title: 'CNPJ/CPF',
      width: '230px',
      render: (data: Record<keyof Customer, any>) => {
        console.log('comentario', data)
        const isCpf = data.document?.length === 11
        const formattedDocument = isCpf
          ? formatCPF(data.document)
          : formatCNPJ(data.document)

        return (
          <Text color="pluxee.text.secondary" fontSize="xxs">
            {formattedDocument}
          </Text>
        )
      }
    },
    {
      title: 'Código do cliente',
      render: (data: Record<keyof Customer, any>) => (
        <Text color="pluxee.text.secondary" fontSize="xxs">
          {data.id}
        </Text>
      )
    },
    {
      title: ' ',
      width: '5%',
      render: () => (
        <Center
          w="40px"
          h="40px"
          as={Button}
          color="#1B51DC"
          borderRadius="0"
          background="transparent"
          _hover={{
            background: 'transparent'
          }}
          _focusVisible={{
            border: '3px solid #1B51DC',
            color: '#0F266D'
          }}
        >
          <Icon
            name="remove_red_eye"
            variant="outlined"
            _hover={{ color: '#0F266D' }}
            _active={{ color: '#0F266D' }}
          />
        </Center>
      )
    }
  ]

  const handleOnClickRow = (customer: Customer) => {
    setStorageItem('customer', JSON.stringify(customer))
    navigate(`/customer/details`)
  }

  const minimumCustomersForPagination = 20

  if (isLoading) return <Skeleton />

  return (
    <Table
      textType="initial"
      minWidth="100%"
      size="sm"
      columns={columns}
      list={customers}
      isPaginated={customers.length >= minimumCustomersForPagination}
      currentPage={currentPage}
      totalPages={customersPagination?.totalPages}
      totalResults={customersPagination?.totalResults}
      onClickRow={handleOnClickRow}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      handleOnChangePage={pageNumber => handleOnChangePage(pageNumber)}
    />
  )
}
