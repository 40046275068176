import Mask from 'hooks/Masks'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import React from 'react'
import { sanitizeObject } from 'utils/sanitizeObject'

import { useCustomer } from '../../../contexts/CustomerContext'
import { hasValidDocumentLength } from '../../../validations/DocumentValidation'
import SearchCustomerPresentational from '../presentational/SearchCustomerPresentational'

const SearchCustomerContainer = () => {
  const [isShowTable, setIsShowTable] = useState(false)
  const [isFirstRequest, setIsFirstRequest] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(true)
  const [value, setValue] = useState('')

  const { getStorageItem, removeStorageItem, setStorageItem } =
    useLocalStorage()
  const { setDocument, isLoading, isError, customers, isSuccess } =
    useCustomer()

  const cachedDocument = getStorageItem('cachedDocument')

  const isEmptyTable =
    (!customers.length && isSuccess && isFirstRequest) || isError

  const handleSearch = useCallback(() => {
    if (!hasValidDocumentLength(value)) return

    const sanitizedValue = sanitizeObject(value)
    setDocument(sanitizedValue.slice(0, 14))
    setStorageItem('cachedDocument', sanitizedValue.slice(0, 14))
    setIsFirstRequest(true)
  }, [setDocument, value])

  const handleInputChange = (inputValue: string) => {
    removeCachedDocument()

    inputValue.length > 11
      ? setValue(Mask.removeMaskCnpj(inputValue))
      : setValue(Mask.removeMaskCpf(inputValue))
  }

  const removeCachedDocument = () => removeStorageItem('cachedDocument')

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedDocument)
    return () => {
      window.removeEventListener('beforeunload', removeCachedDocument)
    }
  }, [removeCachedDocument])

  useEffect(() => {
    if (cachedDocument) {
      setValue(cachedDocument)
      handleSearch()
    }
  }, [cachedDocument, value])

  useLayoutEffect(() => {
    setIsShowTable(customers.length > 0)
  }, [customers])

  useLayoutEffect(() => {
    setIsDisabledButton(!hasValidDocumentLength(value))
  }, [value])

  return (
    <SearchCustomerPresentational
      value={value}
      isShowTable={isShowTable}
      isEmptyTable={isEmptyTable}
      isDisabledButton={isDisabledButton}
      isLoading={isLoading}
      handleInputChange={handleInputChange}
      handleSearch={handleSearch}
    />
  )
}

export default SearchCustomerContainer
