import { Button, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useAuth } from 'contexts/authContext'
import {
  WalletTransaction,
  WalletTransationTypes
} from 'models/WalletTransaction'
import React, { useEffect, useState, useCallback } from 'react'
import { CSVLink } from 'react-csv'
import { useParams } from 'react-router-dom'
import { consumerApi } from 'services/instances/consumerApi'
import { generateCSVFilename } from 'utils/csvUtils'
import { formatToBrl } from 'utils/currencyUtils'
import { formatDateToFilter, formatToDateAndTime } from 'utils/dateUtils'
import * as TransactionUtils from 'utils/transactionsUtils'

type CSVFields = {
  'Data e hora': string
  'ID transação': string
  Descrição: string
  Valor: string
  Status: string
  'Código de resposta': string
  Cartão: string
  Estabelecimento: string
  'Código do estabelecimento': string
  Cidade?: string
  País?: string
  MCC: string | number
  type?: string
}

type ExportButtonProps = {
  transactionType?: WalletTransationTypes
  operationType?: '%2B' | '-'
  type: 'transacoes' | 'entradas' | 'saidas' | 'geral'
  dtStart: string
  dtEnd: string
  isDisabled?: boolean
}

const ExportButton = ({
  dtStart,
  dtEnd,
  type,
  operationType,
  transactionType
}: ExportButtonProps) => {
  const { userLevel } = useAuth()
  const { selectedWallet } = useAccountWallet()
  const { id: accountId } = useParams()

  const [transactionsToCsv, setTransactionsToCsv] = useState<
    WalletTransaction[]
  >([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchTransactionsForCsv = useCallback(async () => {
    if (!accountId) return

    setIsLoading(true)
    const walletId = selectedWallet?.walletId
    const initialDate = dtStart || formatDateToFilter(new Date())
    const finalDate = dtEnd || formatDateToFilter(new Date())

    const transactionTypeFilter = transactionType
      ? `&type=${transactionType}`
      : ''
    const operationTypeFilter = operationType
      ? `&operation=${operationType}`
      : ''
    const dateFilters = `?dtStart=${initialDate}&dtEnd=${finalDate}&size=300${transactionTypeFilter}${operationTypeFilter}`

    const endpoint = walletId
      ? `/accounts/${accountId}/wallets/${walletId}/transactions${dateFilters}`
      : `/accounts/${accountId}/transactions${dateFilters}`

    try {
      const { data } = await consumerApi.get(endpoint, { data: null })
      setTransactionsToCsv(data.transactions)
    } catch (err) {
      console.error('Erro ao buscar transações para CSV:', err)
    } finally {
      setIsLoading(false)
    }
  }, [
    accountId,
    selectedWallet,
    dtStart,
    dtEnd,
    transactionType,
    operationType
  ])

  useEffect(() => {
    fetchTransactionsForCsv()
  }, [fetchTransactionsForCsv])

  const generateCsvData = (includeAllTypes: boolean): CSVFields[] =>
    transactionsToCsv
      .filter(item => includeAllTypes || item.type !== 'PTX')
      .map(item => ({
        'Data e hora': formatToDateAndTime(String(item.transactionDate)),
        'ID transação': item.transactionUUID ?? '',
        Descrição: item.description ?? '',
        Valor: formatToBrl(Number(item.value)),
        Status:
          TransactionUtils.translateStatusToBrazilian({ value: item.status }) ??
          '',
        Cartão: item.cardNumber ?? '',
        Estabelecimento: item.merchantName ?? '',
        'Código do estabelecimento': item.acquirerCode ?? '',
        MCC: item.mcc ?? '',
        'Código de resposta': item.authorizationCode ?? ''
      }))

  const isButtonDisabled =
    transactionsToCsv.length === 0 || (!dtStart && !dtEnd) || isLoading
  const csvData =
    type === 'saidas' ? generateCsvData(false) : generateCsvData(true)

  if (userLevel === 'Atendimento') return null

  return (
    <CSVLink
      filename={generateCSVFilename(new Date(), type)}
      data={csvData}
      style={{ textDecoration: 'none' }}
    >
      <Button
        isDisabled={isButtonDisabled}
        cursor={isButtonDisabled ? 'not-allowed' : 'pointer'}
        w="146px"
        h="40px"
        padding="12px 24px 12px 16px"
        gap="12px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="0"
        border="3px solid #221C46"
        bg="white"
        color="#221C46"
        _hover={{
          bg: !isButtonDisabled && '#C7FCCA',
          borderColor: !isButtonDisabled && '#221C46',
          boxShadow:
            !isButtonDisabled && '4px 4px 0px 0px rgba(34, 28, 70, 0.20)'
        }}
        _focusVisible={{
          bg: !isButtonDisabled && '#C7FCCA',
          outline: !isButtonDisabled && '3px solid #1B51DC'
        }}
        _active={{
          border: !isButtonDisabled && '3px solid #00330E',
          bg: !isButtonDisabled && '#00330E',
          boxShadow:
            !isButtonDisabled && '2px 2px 0px 0px rgba(34, 28, 70, 0.20)',
          color: !isButtonDisabled && 'white'
        }}
      >
        <Icon name="download" />
        <Text fontWeight="700" fontSize="16px" letterSpacing="-0.8px">
          Exportar
        </Text>
      </Button>
    </CSVLink>
  )
}

export default ExportButton
