import { ICustomerRepository } from 'modules/customer/domain/repositories/ICustomerRepository'

import { GetWalletsByCustomerIdProps } from '../queries/GetWalletsByCustomerIdProps'

export class GetWalletsByCustomerId {
  constructor(private customerRepository: ICustomerRepository) {}

  async execute({ customerId }: GetWalletsByCustomerIdProps) {
    return await this.customerRepository.getWalletsByCustomerId(customerId)
  }
}
