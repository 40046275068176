import React from 'react'
import ContentLoader from 'react-content-loader'

const SkeletonWallet = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="70" height="10" />
    <rect x="0" y="30" rx="3" ry="3" width="90" height="50" />
    <rect x="105" y="30" rx="3" ry="3" width="90" height="50" />
    <rect x="210" y="30" rx="3" ry="3" width="90" height="50" />
  </ContentLoader>
)

export default SkeletonWallet
