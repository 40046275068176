import { UpdateWalletToCustomValues } from 'models/request/UpdateWalletToCustomValues'
import { ICustomerRepository } from 'modules/customer/domain/repositories/ICustomerRepository'

export class UpdateWalletToCustomValuesUseCase {
  constructor(private customerRepository: ICustomerRepository) {}

  async execute(customValues: UpdateWalletToCustomValues): Promise<void> {
    await this.customerRepository.updateWalletToCustomValues(customValues)
  }
}
