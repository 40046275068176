import { Flex, Text, Center } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { CustomerWallet } from 'modules/customer/domain/entities/CustomerWallet'
import React from 'react'

import SkeletonWallet from './SkeletonWallet'
import { WalletCard } from './WalletCard'

interface CustomerWalletsPresentationalProps {
  wallets: CustomerWallet[]
  isLoadingWallets: boolean
}

const CustomerWalletsPresentational = ({
  wallets,
  isLoadingWallets
}: CustomerWalletsPresentationalProps) => {
  if (isLoadingWallets) return <SkeletonWallet />

  return (
    <Flex flexDir="column" gap="16px">
      <Text
        color="pluxee.text.primary"
        fontSize="md"
        fontWeight="700"
        lineHeight="31.2px"
        letterSpacing="-0.8px"
      >
        Limites em carteira
      </Text>

      {!wallets?.length && (
        <Center w="100%">
          <NoDataFound />
        </Center>
      )}
      <Flex flexWrap="wrap" gap="32px" alignItems="center">
        {Array.isArray(wallets) &&
          wallets?.map(wallet => (
            <WalletCard key={`customerWallet-${wallet.id}`} {...wallet} />
          ))}
      </Flex>
    </Flex>
  )
}

export default CustomerWalletsPresentational
