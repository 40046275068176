import { GetCustomerByDocumentProps } from 'modules/customer/application/queries/GetCustomerByDocumentProps'
import { ICustomerRepository } from 'modules/customer/domain/repositories/ICustomerRepository'

export class GetCustomerByDocument {
  constructor(private customerRepository: ICustomerRepository) {}

  async execute({ document }: GetCustomerByDocumentProps) {
    return await this.customerRepository.getByDocument(document)
  }
}
