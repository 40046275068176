import type { Customer } from 'models/Customer'
import React from 'react'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'
import { formatCustomerModel, formatCustomerProduct } from 'utils/productUtils'

import { isValidCpf } from '../../../validations/DocumentValidation'
import CustomerInfoPresentational from '../presentational/CustomerInfoPresentational'

const CustomerInfoContainer = ({
  tradeName,
  document,
  products,
  createDate
}: Customer) => {
  const isCPF = isValidCpf(document)
  const formattedClosureDate = '-'
  const formattedCreationDate = formatDateToBrazilianFormat(createDate)

  const { formattedCustomerModel } = formatCustomerModel({ products })
  const { formattedCustomerProduct } = formatCustomerProduct({ products })

  return (
    <CustomerInfoPresentational
      tradeName={tradeName}
      document={document}
      isCPF={isCPF}
      formattedCreationDate={formattedCreationDate}
      formattedClosureDate={formattedClosureDate}
      formattedCustomerModel={formattedCustomerModel}
      formattedCustomerProduct={formattedCustomerProduct}
    />
  )
}

export default CustomerInfoContainer
