import { Flex } from '@chakra-ui/react'
import { useAuth } from 'contexts/authContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { WalletTransationTypes } from 'models/WalletTransaction'
import React, { useCallback, useEffect, useState } from 'react'
import {
  formatDateToBrazilianFormat,
  formatDateToFilter
} from 'utils/dateUtils'

import Calendar, { DateRange } from './Calendar'
import ExportButton from './ExportButton'

export type FilterTransactionsProps = {
  type?: 'transactions' | 'exits' | 'appetizer'
  exportType?: 'transactions' | 'exits' | 'appetizer'
  excludeType?: WalletTransationTypes
  filterByAllTransactions?: boolean
  currentTab?: string // Adiciona a prop currentTab para detectar mudanças
}

const FilterTransactions = ({
  exportType,
  type,
  excludeType,
  filterByAllTransactions = false,
  currentTab // Novo parâmetro que indica a aba atual
}: FilterTransactionsProps) => {
  const { removeStorageItem, setStorageItem, getStorageItem } =
    useLocalStorage()

  const [dateRange, setDateRange] = useState<DateRange>([null, null])
  const [startDate, endDate] = dateRange
  const [calendarInputValue, setCalendarInputValue] = useState<string | null>(
    getStorageItem('datesToFilter') || ''
  )
  const [closeCalendarOnClick, setCloseCalendarOnClick] = useState(false)
  const { userLevel } = useAuth()

  const isAttendant3c = userLevel === 'Atendimento-3C'
  const isAttendantB2M = userLevel === 'Atendimento B2M'

  const { getTransactiosByFilter, getAllTransactions, currentFilters } =
    useWalletTransaction()

  // Reseta o calendário ao trocar de aba
  useEffect(() => {
    setDateRange([null, null])
    setCalendarInputValue('')
    removeStorageItem('datesToFilter')
  }, [currentTab]) // Dependendo do currentTab para resetar

  const handleRemoveFilter = useCallback(() => {
    delete currentFilters.dtStart
    delete currentFilters.dtEnd
    removeStorageItem('datesToFilter')
    setDateRange([null, null])
    setCalendarInputValue('')

    if (filterByAllTransactions) {
      return getAllTransactions({ ...currentFilters })
    }

    getTransactiosByFilter({ ...currentFilters })
  }, [
    filterByAllTransactions,
    currentFilters,
    getAllTransactions,
    getTransactiosByFilter
  ])

  const handleOnChangeDate = useCallback(() => {
    handleRemoveFilter()

    const effectiveEndDate = endDate || startDate

    const dtStart = formatDateToFilter(startDate) || ''
    const dtEnd = formatDateToFilter(effectiveEndDate) ?? undefined
    const startDateBrazil = formatDateToBrazilianFormat(
      startDate?.toISOString()
    )
    const endDateBrazil = formatDateToBrazilianFormat(
      effectiveEndDate?.toISOString()
    )

    const datesToStore =
      startDateBrazil === endDateBrazil
        ? startDateBrazil
        : `${startDateBrazil}  -  ${endDateBrazil || ''}`
    setStorageItem('datesToFilter', datesToStore)
    setCalendarInputValue(datesToStore)

    if (!type) {
      delete currentFilters.type
      getAllTransactions({ ...currentFilters, dtStart, dtEnd })
      return
    }

    if (type === 'exits') {
      delete currentFilters.type
      getTransactiosByFilter({
        ...currentFilters,
        dtStart,
        dtEnd,
        operation: '-',
        excludeType
      })
      return
    }

    if (type === 'transactions') {
      setCloseCalendarOnClick(true)
      delete currentFilters.operation
      getTransactiosByFilter({ ...currentFilters, dtStart, dtEnd, type: 'PTX' })
      return
    }

    if (type === 'appetizer') {
      delete currentFilters.type
      getTransactiosByFilter({
        ...currentFilters,
        dtStart,
        dtEnd,
        operation: '%2B'
      })
      return
    }
  }, [
    startDate,
    endDate,
    type,
    handleRemoveFilter,
    getAllTransactions,
    getTransactiosByFilter,
    currentFilters,
    filterByAllTransactions
  ])

  const renderExportTransactionButton = useCallback(
    (dtStart: string, dtEnd: string) => {
      if (isAttendant3c || isAttendantB2M) return null

      if (!exportType)
        return <ExportButton type="geral" dtStart={dtStart} dtEnd={dtEnd} />

      if (exportType === 'transactions')
        return (
          <ExportButton
            transactionType="PTX"
            type="transacoes"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )

      if (exportType === 'exits')
        return (
          <ExportButton
            operationType="-"
            type="saidas"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )

      if (exportType === 'appetizer')
        return (
          <ExportButton
            operationType="%2B"
            type="entradas"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )
    },
    [startDate, endDate, exportType, isAttendant3c, isAttendantB2M]
  )

  const removeCachedDate = () => removeStorageItem('datesToFilter')

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedDate)
    return () => {
      window.removeEventListener('beforeunload', removeCachedDate)
    }
  }, [removeCachedDate])

  const renderCalendar = useCallback(
    () => (
      <Calendar
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        inputValue={calendarInputValue || ''}
        onChangeDate={handleOnChangeDate}
        onClear={handleRemoveFilter}
        closeOnClick={closeCalendarOnClick}
        h="40px"
        w="340px"
      />
    ),
    [
      calendarInputValue,
      closeCalendarOnClick,
      startDate,
      endDate,
      handleOnChangeDate,
      handleRemoveFilter
    ]
  )

  const startDateToExport = formatDateToFilter(startDate) || ''
  const endDateToExport = formatDateToFilter(endDate) || ''

  return (
    <Flex alignItems="center" justifyContent="flex-end" maxW="507px" gap="16px">
      <Flex>{renderCalendar()}</Flex>
      {renderExportTransactionButton(startDateToExport, endDateToExport)}
    </Flex>
  )
}

export default FilterTransactions
