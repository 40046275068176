import { Flex, Text } from 'components/atoms'
import React from 'react'
import ContentLoader from 'react-content-loader'

import CustomerInfo from '../../CustomerInfo/container/CustomerInfoContainer'

interface CustomerProfilePresentationalProps {
  selectedCustomer:
    | {
        id: number
        tradeName: string
        document: string
        products: any[]
        createDate: string
      }
    | null
    | undefined
}

const CustomerProfilePresentational = ({
  selectedCustomer
}: CustomerProfilePresentationalProps) => {
  return (
    <Flex flexDir="column">
      <Text
        fontSize="md"
        fontWeight="700"
        color="pluxee.text.primary"
        mb="32px"
        letterSpacing="-0.8px"
      >
        Cliente #{selectedCustomer?.id}
      </Text>

      {selectedCustomer ? (
        <CustomerInfo
          tradeName={selectedCustomer.tradeName}
          document={selectedCustomer.document}
          products={selectedCustomer.products}
          createDate={selectedCustomer.createDate}
          name={selectedCustomer.tradeName}
          id={selectedCustomer.id}
        />
      ) : (
        <SkeletonCustomerProfile />
      )}
    </Flex>
  )
}

const SkeletonCustomerProfile = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
    <rect x="0" y="25" rx="3" ry="3" width="27" height="27" />

    <rect x="35" y="25" rx="3" ry="3" width="60" height="8" />
    <rect x="35" y="38" rx="3" ry="3" width="60" height="8" />

    <rect x="110" y="25" rx="3" ry="3" width="60" height="8" />
    <rect x="110" y="38" rx="3" ry="3" width="60" height="8" />
    <rect x="110" y="51" rx="3" ry="3" width="60" height="8" />

    <rect x="185" y="25" rx="3" ry="3" width="60" height="8" />
    <rect x="185" y="38" rx="3" ry="3" width="60" height="8" />
  </ContentLoader>
)

export default CustomerProfilePresentational
